<template>
    <div class="relative">
        <slot />
    </div>
</template>

<script setup lang="ts">
useHead({
    bodyAttrs: {
        class: 'bg-vann text-white font-smn antialiased'
    }
});
</script>

<style>
.page-enter-active,
.page-leave-active {
    transition: opacity 0.2s;
}
.page-enter-from,
.page-leave-to {
    opacity: 0;
}
</style>
